import type { FC } from "react"
import { Container } from "styles/utils/StyledGrid"
import type { RequestBanner } from "../../../../contracts"
import { Banner } from "../Banner"
import { StyledBannerSection } from "../Banner/StyledBanner"

export type SingleHomePropsType = { banner?: RequestBanner }
export const SingleHome: FC<SingleHomePropsType> = ({ banner }) => {
  if (!(!!banner && banner.desktop)) {
    return <></>
  }
  return (
    <StyledBannerSection>
      <Container>
        <Banner
          image={{
            desktop: {
              src: banner.desktop,
              width: 1456,
              height: 386,
              layout: "responsive",
            },
            tablet: {
              src: banner.tablet,
              width: 704,
              height: 186,
              layout: "responsive",
            },
            mobile: {
              src: banner.mobile,
              width: 345,
              height: 134,
              layout: "responsive",
            },
          }}
          url={banner.url}
          loading={"lazy"}
        />
      </Container>
    </StyledBannerSection>
  )
}
